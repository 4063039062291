/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {connect} from 'react-redux';
import Row from '../../../Layout/Grid/Row';
import Column from '../../../Layout/Grid/Column';
import ArticleTeaser from '../../Article/ArticleTeaser/ArticleTeaser';
import Headline from '../../../Elements/Headline/Headline';
import Button from '../../../Elements/Button/Button';
import {ID_POST_LIST} from './constants';
import {PostListStyled, ButtonStyled, ButtonContainerStyled} from './styles';
import {createShapeOfLikes, createShapeOfBookmarks} from '../../Article/ArticleActions/definitions';
import Section from '../../../Layout/Section/Section';

const _ = require('lodash');

let counter3Arrangement = [6, 3, 3];
let gridCondition4 = false;
let gridCondition2 = false;
let gridCondition3 = false;

const handleGridStuff = (gridPoints, counter) => {
	const rnd = Math.floor(Math.random() * Math.floor(4));
	let teaserType = null;
	// a new row begins - handle possible distribution cases
	if (gridPoints === 0) {
		gridCondition2 = counter === 2;
		// distribution must be 6 - 3 - 3 - do not retun immediately - use distribution array
		gridCondition3 = counter === 3;
		gridCondition4 = counter === 4;
		if (counter === 5) {
			teaserType = 6;
			return teaserType;
		}
	}
	// return immediately - there are no other possibilites
	// than 4 elements in a row (not exactly true, there could be 2 sixers :-))
	if (gridCondition4) {
		teaserType = 3;
		return teaserType;
	}
	// return immediately - there are no other possibilites
	if (gridCondition2) {
		teaserType = 6;
		return teaserType;
	}
	if (gridCondition3) {
		teaserType = counter3Arrangement.pop();
		return teaserType;
	}
	// standard cases
	if (gridPoints <= 6) {
		teaserType = rnd === 3 ? 6 : 3;
	} else if (gridPoints >= 9) {
		teaserType = 3;
	}
	return teaserType;
};

const returnFullImageRandom = () => {
	let fullImage = '';
	const rnd = Math.floor(Math.random() * Math.floor(5));
	rnd === 4 ? (fullImage = 'full-image') : (fullImage = '');
	return fullImage;
};

const getFilteredPosts = (posts, ids) => {
	const filteredPosts = posts.filter((post) => {
		const categoryIds = (post.node.categories || []).map((category) =>
			category.name.toLowerCase().replaceAll('ä', 'ae').replaceAll('ü', 'ue')
		);
		const intersectionWithIds = ids.filter((slug) => categoryIds.includes(slug));
		const hasIntersectionWithIds = intersectionWithIds.length;
		return hasIntersectionWithIds;
	});
	return filteredPosts;
};

const getInterestPosts = (posts, nlf) => {
	const interestPosts = posts;
	return interestPosts;
};

const getOnlyVisibleContent = (edges) =>
	_.filter(edges, ({node}) => node.acf.hidden === null || node.acf.hidden === false);

const SECTIONS = {
	SHORT_TEASER_LIST: 'shortTeaserList',
	FULL_TEASER_LIST: 'fullTeaserList',
	NLF_TEASER_LIST: 'nlfTeaserList',
	SEARCH_TEASER_LIST: 'searchTeaserList',
	NOTICED_TEASER_LIST: 'noticedTeaserList',
	RECOMMENDED_TEASER_LIST: 'recommendedTeaserList',
	INTEREST_TEASER_LIST: 'interestTeaserList',
	DOSSIER_LIST: 'dossiersList',
};

class PostList extends React.Component {
	// We don't define any prop as 'required', because in certain cases (-> <ChangeEffect>),
	// no props are given at the very beginning of the lifecycle of this component.

	state = {itemsToShow: 20, render: null};

	static propTypes = {
		section: PropTypes.string,
		posts: PropTypes.arrayOf(PropTypes.object),
		title: PropTypes.string,
		filterCategories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		actions: PropTypes.objectOf(PropTypes.func),
		likes: createShapeOfLikes(),
		bookmarks: createShapeOfBookmarks(),
	};

	static defaultProps = {
		posts: [],
		filterCategories: {},
	};

	componentDidMount() {
		const {
			actions: {getAllLikes, getAllBookmarks},
			user,
		} = this.props;
		// saga call - get likes for all posts
		this.setState({render: 'RENDER'});
		getAllLikes();
		if (!!user && user.UserId) {
			const currentUser = user.UserId;
			getAllBookmarks(currentUser);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {likes, bookmarks} = this.props;
		const articleLikeChanged = nextProps.likes.articleLike !== likes.articleLike;
		const articleBeingProcessed = nextProps.likes.likeBeingProcessed !== likes.likeBeingProcessed;
		// changed likes and bookmarks will have no impact to PostList - child components have their own lifecycle and render independently
		const comparisonLikes = nextProps.likes !== likes;
		const renderState = nextState.render !== this.state.render;
		if (renderState) {
			return true;
		}

		if (this.props.section === SECTIONS.NOTICED_TEASER_LIST) {
			if (bookmarks.results.length !== nextProps.bookmarks.results.length) {
				return true;
			}
		}
		// const articleBookmarkChanged = nextProps.bookmarks.articleBookmark !== bookmarks.articleBookmark;
		const articleBookmarkBeingProcessed =
			nextProps.bookmarks.bookmarkBeingProcessed !== bookmarks.bookmarkBeingProcessed;
		const comparisonBookmarks = nextProps.bookmarks !== bookmarks;
		if (
			articleLikeChanged ||
			articleBeingProcessed ||
			comparisonLikes ||
			// articleBookmarkChanged ||
			articleBookmarkBeingProcessed ||
			comparisonBookmarks
		) {
			return false;
		}
		return true;
	}

	getPosts(posts) {
		const {filterCategories} = this.props;
		return filterCategories.length === 0 ? posts : getFilteredPosts(posts, filterCategories);
	}

	renderHeadline = (text) => {
		// id={ID_POST_LIST} is needed to identify scroll-target
		return <Headline as="h2" center text={text} id={ID_POST_LIST} />;
	};

	loadMorePost = () => {
		const {itemsToShow} = this.state;
		this.setState({itemsToShow: itemsToShow + 20});
	};

	renderSearchList(postsRender) {
		const {title, likes} = this.props;
		// likes must be present
		if (likes.results === undefined) {
			return null;
		}
		const postsToRender = postsRender;
		return (
			<PostListStyled>
				{this.renderHeadline(title)}
				<Row>
					{postsToRender.map(({node: post}, index) => {
						const fullImage = returnFullImageRandom();
						const teaserTypeMd = 6;
						const teaserTypeXl = 4;
						return (
							<Column key={post.id} xl={teaserTypeXl} lg={teaserTypeMd}>
								<ArticleTeaser
									position={index + 1}
									item={{
										appearance: fullImage,
										isSearchResult: true,
										label: post.primary_category ? post.primary_category.name : '',
										title: post.title,
										date: post.date,
										text: post.excerpt,
										link: {
											url: post.slug,
										},
										wordpressId: post.wordpress_id,
										image: {
											localFile: {
												publicURL:
													post.featured_media && post.featured_media.localFile
														? post.featured_media.localFile.childImageSharp.fluid
														: null,
											},
										},
									}}
								/>
							</Column>
						);
					})}
				</Row>
			</PostListStyled>
		);
	}

	renderList(postsRender) {
		const {title, likes, bookmarks} = this.props;
		// likes must be present
		if (likes.results === undefined) {
			return null;
		}
		const postsToRender = getOnlyVisibleContent(postsRender);
		const likesResult = likes.results;
		const bookmarksResult = [{post_id: 1272}];
		let gridPoints = 0;
		let counter = postsToRender.length;
		counter3Arrangement = [6, 3, 3];
		gridCondition2 = false;
		gridCondition3 = false;
		gridCondition4 = false;
		return (
			<PostListStyled>
				{this.renderHeadline(title)}
				<Row>
					{postsToRender.map(({node: post}, index) => {
						const like = likesResult.find((elem) => elem.post_id === post.wordpress_id);
						const bookmark = bookmarksResult.find((elem) => elem.post_id === post.wordpress_id);
						const fullImage = returnFullImageRandom();
						const teaserType = handleGridStuff(gridPoints, counter);
						gridPoints += teaserType;
						counter -= 1;
						if (gridPoints === 12) {
							gridPoints = 0;
						}
						return (
							<Column key={post.id} xl={teaserType} lg={6}>
								<ArticleTeaser
									position={index + 1}
									item={{
										appearance: fullImage,
										wordpressId: post.wordpress_id,
										isSearchResult: false,
										label: post.primary_category ? post.primary_category.name : '',
										title: post.title,
										date: post.date,
										text: post.excerpt,
										like,
										bookmark,
										link: {
											url: `/${post.path}`,
										},
										image: {
											localFile: {
												publicURL:
													post.featured_media && post.featured_media.localFile
														? post.featured_media.localFile.childImageSharp.fluid
														: null,
											},
										},
									}}
								/>
							</Column>
						);
					})}
				</Row>
			</PostListStyled>
		);
	}

	renderDossier(postsRender) {
		const {title} = this.props;

		let gridPoints = 0;
		let counter = postsRender.length;
		counter3Arrangement = [6, 3, 3];
		gridCondition2 = false;
		gridCondition3 = false;
		gridCondition4 = false;
		return (
			<PostListStyled>
				{this.renderHeadline(title)}
				<Row>
					{postsRender.map(({node: post}, index) => {
						const fullImage = returnFullImageRandom();
						const teaserType = handleGridStuff(gridPoints, counter);
						gridPoints += teaserType;
						counter -= 1;
						if (gridPoints === 12) {
							gridPoints = 0;
						}
						return (
							<Column key={post.id} xl={teaserType} lg={6}>
								<ArticleTeaser
									position={index + 1}
									item={{
										appearance: fullImage,
										wordpressId: post.wordpress_id,
										isSearchResult: false,
										label: post.primary_category ? post.primary_category.name : '',
										title: post.title,
										date: post.date,
										text: post.excerpt,

										link: {
											url: `/multiple-sklerose/${post.slug}`,
										},
										image: {
											localFile: {
												publicURL:
													post.featured_media && post.featured_media.localFile
														? post.featured_media.localFile.childImageSharp.fluid
														: null,
											},
										},
									}}
								/>
							</Column>
						);
					})}
				</Row>
			</PostListStyled>
		);
	}

	renderShortTeaserList() {
		const {posts} = this.props;
		const postsPartial = posts.slice(0, 6);
		return (
			<>
				{this.renderList(postsPartial)}
				<ButtonContainerStyled>
					<ButtonStyled text="Zu deinem Stream" type="link" to="/leben-mit-ms" />
				</ButtonContainerStyled>
			</>
		);
	}

	renderFullTeaserList() {
		const {posts, filterCategories} = this.props;
		const {itemsToShow} = this.state;
		const filteredPosts = this.getPosts(posts);
		let finalPosts = filteredPosts;

		if (filterCategories && filterCategories.length === 0) {
			finalPosts = filteredPosts.slice(0, itemsToShow);
		}
		let loadMoreButton = null;
		if (itemsToShow <= posts.length && filterCategories.length === 0) {
			loadMoreButton = (
				<Section center>
					<Button onClick={this.loadMorePost} text="Mehr Artikel laden..." />
				</Section>
			);
		}
		return (
			<>
				{this.renderList(finalPosts)}
				{loadMoreButton}
			</>
		);
	}

	renderDossierList() {
		const {posts, filterCategories} = this.props;
		const {itemsToShow} = this.state;
		const filteredPosts = this.getPosts(posts);
		let finalPosts = filteredPosts;

		if (filterCategories && filterCategories.length === 0) {
			finalPosts = filteredPosts.slice(0, itemsToShow);
		}
		let loadMoreButton = null;
		if (itemsToShow <= posts.length && filterCategories.length === 0) {
			loadMoreButton = (
				<Section center>
					<Button onClick={this.loadMorePost} text="Mehr Artikel laden..." />
				</Section>
			);
		}
		return (
			<>
				{this.renderDossier(posts)}
				{loadMoreButton}
			</>
		);
	}

	renderSearchTeaserList() {
		const {posts} = this.props;
		return this.renderSearchList(posts);
	}

	renderNLFTeaserList() {
		const {posts} = this.props;
		const {itemsToShow} = this.state;
		const filteredPosts = this.getNLFPosts(posts);
		const finalPosts = filteredPosts;

		let loadMoreButton = null;
		8;
		if (itemsToShow <= posts.length === 0) {
			loadMoreButton = (
				<Section center>
					<Button onClick={this.loadMorePost} text="Mehr Artikel laden..." />
				</Section>
			);
		}
		return (
			<>
				{this.renderList(finalPosts)}
				{loadMoreButton}
			</>
		);
	}

	filterBookmarks = (posts, bookmarks) => {
		const bkmrks = [];
		for (let i = 0; i < bookmarks.results.length; i += 1) {
			bkmrks.push(parseInt(bookmarks.results[i].post_id, 10));
		}
		const filtered = posts.filter((post) => {
			return bkmrks.indexOf(post.node.wordpress_id) > -1;
		});
		return filtered;
	};

	getRecommendedPostCategories = (posts, bookmarks) => {
		const catIds = [];
		const filteredPosts = this.filterBookmarks(posts, bookmarks);
		for (let i = 0; i < filteredPosts.length; i += 1) {
			const post = filteredPosts[i];
			const categoryIds = post.node.categories.map((node) => {
				return node.name;
			});
			for (let y = 0; y < categoryIds.length; y += 1) {
				const cat = categoryIds[y];
				if (!catIds.includes(cat)) {
					catIds.push(cat);
				}
			}
		}
		const postsFiltered = getFilteredPosts(posts, catIds);
		return postsFiltered;
	};

	getInterestPosts = (posts) => {
		const postsFiltered = getInterestPosts(posts);
		return postsFiltered;
	};

	getNLFPosts = (posts, nlf) => {
		const {user} = this.props;
		if (!user && !user.nlf) {
			return null;
		}
		const _nlf = nlf || user.nlf.toString();
		const filterPosts = posts.filter((post) => _.includes(post.node.acf.persona, _nlf));
		return filterPosts;
	};

	renderRecommendedPosts() {
		const {posts, bookmarks} = this.props;
		const randomIndizes = [];
		let partsPosts = [];
		if (!posts) {
			return null;
		}
		if (!bookmarks) {
			return null;
		}
		if (bookmarks.results.length === 0) {
			return (
				<PostListStyled>
					<Headline as="h2" center text="Empfohlene Artikel" />
					<Row>Hier erscheinen Beitragsemfehlungen, sobald du Beiträge als Lesezeichen markiert hast.</Row>
				</PostListStyled>
			);
		}
		const visiblePosts = getOnlyVisibleContent(posts);
		let recommendedPosts = this.getRecommendedPostCategories(visiblePosts, bookmarks);

		bookmarks.results.map((_id) => {
			const postID = parseInt(_id.post_id, 10);
			recommendedPosts = recommendedPosts.filter((post) => post.node.wordpress_id !== postID);
		});

		if (recommendedPosts.length < 5) {
			partsPosts = recommendedPosts.slice(0, 3);
			return this.renderList(partsPosts);
		}

		while (partsPosts.length < 3) {
			const rnd = Math.floor(Math.random() * Math.floor(recommendedPosts.length));
			if (randomIndizes.indexOf(rnd) === -1) {
				randomIndizes.push(rnd);
				partsPosts.push(recommendedPosts[rnd]);
			}
		}
		return this.renderList(partsPosts);
	}

	renderInterestPosts() {
		const {posts, user, bookmarks} = this.props;

		const randomIndizes = [];
		let partsPosts = [];
		if (!posts) {
			return null;
		}
		if (user && user.nlf < 14) {
			return (
				<PostListStyled>
					<Headline as="h2" center text="Das könnte dich auch interessieren" />
					<Row>
						Hier erscheinen Beitragsemfehlungen, sobald du Deine Angaben in "Meine Angaben" ausgefüllt hast.
					</Row>
				</PostListStyled>
			);
		}

		const visiblePosts = getOnlyVisibleContent(posts);
		let interestPosts = this.getInterestPosts(visiblePosts);

		// let newStream = []

		if (bookmarks && bookmarks.results) {
			bookmarks.results.map((_id) => {
				const postID = parseInt(_id.post_id, 10);
				interestPosts = interestPosts.filter((post) => post.node.wordpress_id !== postID);
			});
		}

		if (user && user.nlf > 12) {
			const {nlf} = user;
			interestPosts = this.getNLFPosts(visiblePosts, nlf);
		}

		if (interestPosts.length === 0) {
			return null;
		}

		if (interestPosts.length < 5) {
			partsPosts = interestPosts.slice(0, 3);
			return this.renderList(partsPosts);
		}

		while (partsPosts.length < 3) {
			const rnd = Math.floor(Math.random() * Math.floor(interestPosts.length));
			if (randomIndizes.indexOf(rnd) === -1) {
				randomIndizes.push(rnd);
				partsPosts.push(interestPosts[rnd]);
			}
		}

		return this.renderList(partsPosts);
	}

	renderNLFPosts() {
		const {posts} = this.props;

		if (!posts) {
			return null;
		}

		const visiblePosts = getOnlyVisibleContent(posts);
		const interestPosts = this.getNLFPosts(visiblePosts);

		return this.renderList(interestPosts);
	}

	renderBookmarkedPosts() {
		const {posts, bookmarks} = this.props;
		if (!posts) {
			return null;
		}
		if (!bookmarks) {
			return null;
		}
		if (bookmarks.results.length === 0) {
			return (
				<PostListStyled>
					<Headline as="h2" center text="Gemerkte Artikel" />
					<Row>
						Du hast noch keine gemerkten Artikel. Artikel kannst du unter "Leben mit MS" als Lesezeichen
						markieren.
					</Row>
				</PostListStyled>
			);
		}
		const filteredPosts = this.filterBookmarks(posts, bookmarks);
		return this.renderList(filteredPosts);
	}

	render() {
		// possible list types:
		// fullTeaserList # shortTeaserList # searchTeaserList # noticedTeaserList
		const {section} = this.props;
		const {render} = this.state;

		// if there is a window object we are out of the build scope and can do null checks
		if (typeof window !== 'undefined') {
			if (render === null) {
				return null;
			}
		}
		if (section === SECTIONS.SHORT_TEASER_LIST) {
			return this.renderShortTeaserList();
		}
		if (section === SECTIONS.FULL_TEASER_LIST) {
			return this.renderFullTeaserList();
		}
		if (section === SECTIONS.DOSSIER_LIST) {
			return this.renderDossierList();
		}
		if (section === SECTIONS.NLF_TEASER_LIST) {
			return this.renderNLFTeaserList();
		}
		if (section === SECTIONS.SEARCH_TEASER_LIST) {
			return this.renderSearchTeaserList();
		}
		if (section === SECTIONS.NOTICED_TEASER_LIST) {
			return this.renderBookmarkedPosts();
		}
		if (section === SECTIONS.RECOMMENDED_TEASER_LIST) {
			return this.renderRecommendedPosts();
		}

		if (section === SECTIONS.INTEREST_TEASER_LIST) {
			return this.renderInterestPosts();
		}
		return null;
	}
}

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(PostList);

export const pageQuery = graphql`
	fragment PostListFields on wordpress__POST {
		id
		title
		excerpt
		wordpress_id
		acf {
			hidden
			persona
		}
		primary_category {
			name
		}
		categories {
			id
			name
			slug
		}
		date(formatString: "MMMM DD, YYYY")
		slug
		path
		featured_media {
			localFile {
				childImageSharp {
					id
					fluid {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	}
`;

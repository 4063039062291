import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as FilterActions from '../../../../state/actions/default';
import PostList from './PostList';
import ChangeEffect from '../../../Layout/ChangeEffect/ChangeEffect';

const mapStateToProps = (state) => ({
	filterCategories: state.filterCategories,
	searchResults: state.searchResults,
	likes: state.likes,
	bookmarks: state.bookmarks,
});

const mapDispatchToProps = (dispatch) => {
	return {actions: bindActionCreators({...FilterActions}, dispatch)};
};

export default connect(mapStateToProps, mapDispatchToProps)(PostList);

/**
 * Special case: PostList showing visual effect on re-render, i.e. when prop 'filterCategories' changes.
 */
export const PostListContainerWithChangeEffect = connect(
	mapStateToProps,
	mapDispatchToProps
)((props) => (
	<ChangeEffect duration={500} offset={200} propToObserve={props.filterCategories}>
		<PostList {...props} />
	</ChangeEffect>
));

import styled from 'styled-components';
import {sizes} from '../../../../styles/variables';
import {withUnit} from '../../../../styles/utils';
import Button from '../../../Elements/Button/Button';

export const PostListStyled = styled.div`
	margin-bottom: ${withUnit(sizes.distance.base)};
`;

export const ButtonStyled = styled(Button)`
	align-self: center;
	margin: 0;
`;

export const ButtonContainerStyled = styled.div`
	display: flex;
	justify-content: center;
`;
